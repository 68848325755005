import React from 'react';
// import sections

import JumpyPrivacyPolicy from '../components/sections/JumpyPrivacyPolicy';


const PrivacyPolicy = () => {

  return (
    <>
      
      <JumpyPrivacyPolicy />

    </>
  );
}

export default PrivacyPolicy;
import React from 'react';
// import sections

import ContactUsForm from '../components/sections/ContactUsForm';

const ContactUs = () => {

  return (
    <>
      <ContactUsForm />
    </>
  );
}

export default ContactUs;
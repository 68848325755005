import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const JumpyPrivacyPolicy = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Privacy Policy',
    paragraph: 'We respect your preferences concerning the treatment of Information that we may collect. This Privacy Policy explains what kind of information we collect, use, share and the security of your information in relation to our mobile applications (“Applications” or “Apps”) and mobile services (“Services”). Please take a moment to familiarize yourself with our privacy practices. BY INSTALLING THE APPS ON YOUR MOBILE DEVICE, ENTERING INTO, CONNECTING TO, ACCESSING AND/OR USING THE APPS, YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY, INCLUDING TO THE POSSIBLE COLLECTION AND PROCESSING OF YOUR INFORMATION AND YOU ARE CONSENTING TO THE USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES ON YOUR DEVICE. PLEASE NOTE: IF YOU OR, AS APPLICABLE, YOUR LEGAL GUARDIAN, DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT INSTALL, ACCESS AND/OR USE THE APPS AND YOU ARE REQUESTED TO PROMPTLY ERASE THEM, AND ANY PART THEREOF, FROM YOUR MOBILE DEVICE.'
  };

  return (
   
    <section
      {...props}
      className={outerClasses}
    >
      <div class="container">
        <h2>Privacy Policy</h2>
        <p>We respect your preferences concerning the treatment of Information that we may collect. This Privacy Policy explains what kind of information we collect, use, share and the security of your information in relation to our mobile applications (“Applications” or “Apps”) and mobile services (“Services”). Please take a moment to familiarize yourself with our privacy practices. BY INSTALLING THE APPS ON YOUR MOBILE DEVICE, ENTERING INTO, CONNECTING TO, ACCESSING AND/OR USING THE APPS, YOU AGREE TO THE TERMS AND CONDITIONS SET FORTH IN THIS PRIVACY POLICY, INCLUDING TO THE POSSIBLE COLLECTION AND PROCESSING OF YOUR INFORMATION AND YOU ARE CONSENTING TO THE USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES ON YOUR DEVICE. PLEASE NOTE: IF YOU OR, AS APPLICABLE, YOUR LEGAL GUARDIAN, DISAGREE TO ANY TERM PROVIDED HEREIN, YOU MAY NOT INSTALL, ACCESS AND/OR USE THE APPS AND YOU ARE REQUESTED TO PROMPTLY ERASE THEM, AND ANY PART THEREOF, FROM YOUR MOBILE DEVICE.</p> 
        <h5>What information we collect</h5>
        <p>Our primary purpose in collecting information is to provide you with a safe, efficient and customized experience and to provide you with Services and features that better meet your needs or requests.</p>
        <h6>1. Personal Information</h6>
        <p>We do NOT collect any Personal Information about you. “Personal Information” means personally identifiable information, such as your name, email address, physical address, calendar entries, contact entries, files, photos, etc.</p>
        <h6>2. Non - Personal Information</h6>
        <p> We do NOT collect any Non - Personal Information. “Non-Personal Information” means information that is of an anonymous nature, such as the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, and information about the way you use the Applications.</p>

        <h5>How we use your information we collect</h5>
        
        <h8>1. Personal Information</h8>
        <p>We do NOT collect and use Personal Information in any way.</p>
        <h8>2. Non - Personal Information</h8>
        <p>We do NOT collect and use  Non - Personal Information in any way.</p>

        <h5>How we share the information we collect</h5>
        
        <h8>1. Personal Information</h8>
        <p>We do NOT collect and share Personal Information in any way.</p>
        <h8>2. Non - Personal Information</h8 >
        <p>We do NOT collect and share Non - Personal Information in any way.</p>
        <h5>Our Advertising Technologies</h5>
        <p>We are using <b>AdMob</b> Advertising Channel to advertise on the app, you can refer to their policy here:</p> 
<a href="https://www.google.com/policies/privacy"><u>https://www.google.com/policies/privacy</u></a>

        <h5>Changes to our Privacy Policy</h5>
        <p>This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy at: <a href="http://www.kodinghut.in/privacy-policy/" ><u>http://www.kodinghut.in/privacy-policy/</u></a> </p>
       
        <p>Your continued use of the Apps and Services following the posting of changes will mean you accept those changes.</p>

        <h5>Contact us</h5>
        <p>If you have any questions regarding privacy while using the Apps, or have questions about our practices, please contact us via email at    
          <a href="mailto: kodinghut@gmail.com" > kodinghut@gmail.com</a></p>
      </div>
      


      
    </section>
  );
}

JumpyPrivacyPolicy.propTypes = propTypes;
JumpyPrivacyPolicy.defaultProps = defaultProps;

export default JumpyPrivacyPolicy;